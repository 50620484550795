import { DEFAULT_PALETTE_COLORS } from '../../../../data/constants';
import { AppButton } from '../../AppButton/AppButton';
import { ColorItem } from '../ColorItem/ColorItem';
import styles from './ColorPalette.module.scss';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { createPortal } from 'react-dom';
import { ColorPicker } from '../../ColorPicker/ColorPicker';
import { useDispatch } from 'react-redux';

const DEFAULT_COLOR = '#394eca';

export const ColorPalette = ({
  colors = [],
  value,
  onChange,
  onAvailableChanged,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [pos, setPos] = useState([0, 0]);
  const [colorAdded, setColorAdded] = useState(false);

  const addMore = (ev) => {
    ev.stopPropagation();
    setPos([ev.pageX - 50, ev.pageY]);
    setShowPicker(true);
    setColorAdded(false);
  };

  const colorChosen = () => {
    setShowPicker(false);
  };

  const setNewColor = (val) => {
    if (!colorAdded) {
      setColorAdded(true);
      onAvailableChanged([...colors, val].slice(1));
    } else {
      const items = [...colors];
      items.pop();
      let result = [...items, val];
      if (result.length > 11) {
        result.shift();
      }
      onAvailableChanged(result);
    }
    setTimeout(() => {
      onChange(val);
    }, 50);
  };

  return (
    <div className={styles.palette}>
      <div className={styles.colors}>
        {colors.slice(0, 11).map((color) => (
          <ColorItem
            color={color}
            checked={value === color}
            onClick={() => onChange(color)}
            key={color}
          />
        ))}
      </div>
      <AppButton
        size="small"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={addMore}
      >
        Добавить свой
      </AppButton>
      {showPicker &&
        createPortal(
          <ColorPicker
            x={pos[0]}
            y={pos[1]}
            onClose={colorChosen}
            onChange={setNewColor}
          />,
          document.body
        )}
    </div>
  );
};
